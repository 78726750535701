<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Relatório de romaneios</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                    <el-dropdown
                      @command="(c) => (packingStatus = c) | fetchPackings()"
                      size="medium"
                      trigger="click"
                    >
                      <el-button type="primary" size="medium">
                        Filtrar por: {{ PackingStatus[packingStatus] || ""
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="checking">
                            <li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: packingStatus === 'checking',
                              }"
                            >
                              Conferência
                            </li>
                          </el-dropdown-item>
                          <el-dropdown-item command="checked"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: packingStatus === 'checked',
                              }"
                            >
                              Conferidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="analysis"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: packingStatus === 'analysis',
                              }"
                            >
                              Análise
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="completed"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: packingStatus === 'completed',
                              }"
                            >
                              Finalizados
                            </li></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-row>
                </el-col>
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterPackingModal()"
                ></el-button>
                <el-button
                  type="primary"
                  class="mb-0"
                  icon="el-icon-download"
                  :loading="isLoadingDownloadButton"
                  size="medium"
                  @click="downloadReport()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Packings?.data"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="code" label="Nº"> </el-table-column>
      <el-table-column
        prop="bought_at"
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor" prop="provider.name"> </el-table-column>
      <el-table-column label="animais" prop="animals.length"></el-table-column>
      <el-table-column label="peso aferido">
        <template #default="p">
          {{
            `${numberFormatter.format(
              p?.row?.animals
                ?.map((a) => a.carcasses)
                ?.flat()
                ?.reduce((t, e) => (t += Number(e?.weight || 0)), 0) || 0
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="peso declarado">
        <template #default="p">
          {{
            `${numberFormatter.format(
              p?.row?.animals?.reduce(
                (t, e) =>
                  (t += Number(e?.weight || 0) + Number(e?.weight_ || 0)),
                0
              ) || 0
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="preço total">
        <template #default="p">
          {{
            `${currencyFormatter.format(
              p?.row?.animals?.reduce(
                (t, e) =>
                  (t +=
                    (Number(e?.weight || 0) + Number(e?.weight_ || 0)) *
                    Number(e?.price || 0)),
                0
              ) -
                Number(p?.row?.provider?.tax || 0) *
                  Number(p?.row?.total || 0) -
                p?.row?.animals?.reduce(
                  (t, e) =>
                    (t +=
                      (Number(e?.weight || 0) + Number(e?.weight_ || 0)) *
                      Number(e?.price || 0)),
                  0
                ) *
                  (Number(p?.row?.provider?.deduction || 0) / 100) || 0
            )}`
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-row v-show="Packings?.data?.length">
      <el-col :md="3">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="3"> </el-col>
      <el-col :md="4"> </el-col>
      <el-col :md="4"> </el-col>
      <el-col :md="4"> </el-col>
      <el-col :md="3">
        <p class="summary summary-black">
          {{
            currencyFormatter.format(
              Packings?.data
                ?.map(
                  (p) =>
                    p?.animals?.reduce(
                      (t, e) =>
                        (t +=
                          (Number(e?.weight || 0) + Number(e?.weight_ || 0)) *
                          Number(e?.price || 0)),
                      0
                    ) -
                      Number(p?.provider?.tax || 0) * Number(p?.total || 0) -
                      p?.animals?.reduce(
                        (t, e) =>
                          (t +=
                            (Number(e?.weight || 0) + Number(e?.weight_ || 0)) *
                            Number(e?.price || 0)),
                        0
                      ) *
                        (Number(p?.provider?.deduction || 0) / 100) || 0
                )
                .reduce((t, e) => (t += e), 0) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Packings?.lastPage"
    >
    </el-pagination>
    <filter-packing-modal
      :showModal="showFilterPackingModal"
      @close-modal="showFilterPackingModal = false"
      @update-filters="updateFilters"
    ></filter-packing-modal>
  </el-card>
</template>

<script>
import FilterPackingModal from "./modals/FilterPackingListModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "PackingListPage",
  components: {
    FilterPackingModal,
  },
  data: () => ({
    hasError: false,
    isLoading: false,
    showBillModal: false,
    showFilterPackingModal: false,
    packings: [],
    currentPage: 1,
    isLoadingDownloadButton: false,
    showPackingListCheckModal: false,
    showPackingListAnimalsModal: false,
    packingStatus: "checking",
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
    partners: null,
    packing: null,
    filterOptions: {},
    showPackingListModal: false,
  }),
  watch: {
    packings(v) {
      if (this.packing)
        this.packing = v?.data?.find((p) => p.uid == this.packing.uid);
    },
    currentPage() {
      this.fetchPackings();
    },
    packingStatus() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.fetchPackings();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Packings() {
      return this.packings || [];
    },
    PackingStatus() {
      return {
        checking: "Conferência",
        checked: "Conferidos",
        analysis: "Análise",
        completed: "Finalizados",
      };
    },
  },
  methods: {
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchPackings();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openFilterPackingModal() {
      this.showFilterPackingModal = true;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}packings/report`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
        status: this.packingStatus,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Romaneios.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    updateProviderSelect(packing, data) {
      packing.provider_id = data;
      this.updatePacking(packing);
    },
    openBillsModal() {
      this.showBillModal = true;
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    hasProblems(packing) {
      return (
        this.anyAnimalHasLessThan2SelectedCarcasses(packing) ||
        this.anyPriceWasUpdatedAfterSetStandard(packing) ||
        this.animalWeightAndCarcassesWeightDifferMoreThanThreePercent(packing)
      );
    },
    showProblem(packing) {
      var message = "Nenhum problema encontrado";
      if (
        this.animalWeightAndCarcassesWeightDifferMoreThanThreePercent(packing)
      )
        message = "O peso declarado e o peso aferido diferem em mais de 3%";
      if (this.anyPriceWasUpdatedAfterSetStandard(packing))
        message = "O preço de algum animal foi definido manualmente";
      if (this.anyAnimalHasLessThan2SelectedCarcasses(packing))
        message = "Um animal possui apenas uma ou nenhuma carcaça selecionada";

      this.$alert(message, "Problema no romaneio", { confirmButtonText: "Ok" });
    },
    openPackingListAnimalsModal(e) {
      (this.packing = e), (this.showPackingListAnimalsModal = true);
    },
    openPackingListCheckModal(e) {
      this.packing = e;
      this.showPackingListCheckModal = true;
    },
    anyAnimalHasLessThan2SelectedCarcasses(packing) {
      return packing?.animals?.some((a) => a?.carcasses?.length < 2);
    },
    anyPriceWasUpdatedAfterSetStandard(packing) {
      return packing?.animals?.some(
        (a) =>
          a.price !=
          (packing?.provider?.prices?.find((p) => p.standard === a.standard)
            ?.price || a.price)
      );
    },
    animalWeightAndCarcassesWeightDifferMoreThanThreePercent(packing) {
      const sumWeightAnimals = packing?.animals?.reduce(
        (t, a) => (t += (Number(a.weight) || 0) + (Number(a.weight_) || 0)),
        0
      );
      const sumWeightCarcasses = packing?.animals
        ?.map((a) => a?.carcasses)
        ?.flat()
        ?.reduce((t, c) => (t += Number(c.weight)), 0);

      return (
        Math.abs(
          ((sumWeightAnimals - sumWeightCarcasses) / sumWeightAnimals) * 100
        ) >= 3
      );
    },
    updatePacking(packing) {
      if (packing)
        fetch(`${this.$store.state.apiUrl}packings/${packing.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...packing }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchPackings();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar o romaneio",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
    },
    fetchPackings() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}packings`);
      url.search = new URLSearchParams({
        pagination: this.currentPage,
        status: this.packingStatus,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.packings = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>
